<template lang="pug">
.edit-profile(v-if="questionnaire && profile")
  h1 Perfil de beleza

  QuestionnaireWarning(v-if="showWarning", :remainingProducts="numberOfProductTypesStillNeeded")

  Questionnaire(
    ref="questionnaireComponent",
    v-show="!showWarning",
    :questionnaire="questionnaire",
    :answers="profile.answers",
    :current="currentQuestion")

  .actions(v-if="!showWarning")
    IconButton(
      :icon="[ 'fa', 'chevron-left' ]"
      :class="{ hide: !hasPrevious }",
      to="",
      @click.prevent="previous")

    Button(
      label="Salvar",
      to="",
      @click.prevent="save",
      :loading="saving",
      size="large",
      rounded
    )

    IconButton(
      :icon="[ 'fa', 'chevron-right' ]"
      :class="{ hide: !hasNext }",
      to="",
      @click.stop.prevent="next")

  .actions(v-else="showWarning")
    .spacer

    Button(
      label="Voltar",
      to="",
      @click.stop.prevent="hideWarning",
      size="large"
      rounded)

    .spacer

.edit-profile-loading(v-else)
  Loading
</template>
<script setup lang="ts">
import type { WatchStopHandle } from 'vue'
import type { Questionnaire } from '~/services/profile/questionnaireService'
import type { Profile } from '~/services/profile/profileService'

import QuestionnaireService from '~/services/profile/questionnaireService'
import ProfileService from '~/services/profile/profileService'

import QuestionnaireComponent from '~/components/profile/questionnaire/Questionnaire.vue'

useHead({
  title: 'Perfil de beleza'
})

definePageMeta({
  key: "dashboard/profile",
  requiresAuth: true,
  breadcrumb: [
    {
      title: 'Minha conta',
      link: { name: 'dashboard' },
    },
    {
      title: 'Perfil de beleza',
      link: { name: 'edit-profile' },
    },
  ],
})

const router = useRouter()
const route = useRoute()

const notifications = useNotifications()

const questionnaireComponent = ref<InstanceType<typeof QuestionnaireComponent>>()
const currentQuestion = ref(1)
const saving = ref(false)
const showWarning = ref(false)

const { data, error } = await useAsyncData(() => {
  return Promise.all([QuestionnaireService.fetchLatest(), ProfileService.findProfileLatestRevision()])
})

reportOnError(error, 'Failed to load questionnaire and profile')

const questionnaire = ref(data.value?.[0] as Questionnaire || null)
const profileResponse = ref(data.value?.[1] as Profile || null)

const profile = ref(questionnaire.value && profileResponse.value ? {
  ...profileResponse.value,
  answers: ProfileService.cleanUpProfile(profileResponse.value.answers, questionnaire.value)
} : null)

var watchStepParamHandle: WatchStopHandle
onMounted(async () => {
  watchStepParamHandle = watch(() => route.params.step, updateStep)
})

onUnmounted(() => {
  if (watchStepParamHandle) {
    watchStepParamHandle()
  }
})

const hasPrevious = computed(() => currentQuestion.value > 1)
const hasNext = computed(() => currentQuestion.value < (questionnaire.value?.questions?.length ?? 0))

const numberOfProductTypesStillNeeded = computed(() => {
  return questionnaireComponent.value?.numberOfProductTypesStillNeeded ?? 0
})

const previous = () => {
  navigateTo({ name: route.name!, params: { step: currentQuestion.value - 1 } })
}

const next = () => {
  navigateTo({ name: route.name!, params: { step: currentQuestion.value + 1 } })
}

const save = async () => {
  if ((questionnaireComponent.value?.numberOfProductTypesStillNeeded ?? 0) > 0) {
    showWarning.value = true

    return
  }

  saving.value = true

  try {
    const questionnaireId = questionnaire.value?.id
    const profileId = profile.value?.id

    if (questionnaireId && profileId) {
      await ProfileService.updateProfile(profileId, {
        questionnaireId,
        answers: profile.value?.answers ?? []
      })

      notifications.info("Perfil de beleza salvo com sucesso")
    }
  } catch (error) {
    captureException(new Error('Failed to save profile', { cause: error }))

    notifications.error("Não foi possível salvar os dados do seu perfil de beleza")
  } finally {
    saving.value = false
  }
}

const hideWarning = () => {
  showWarning.value = false

  const firstProductTypeStep = (questionnaire.value?.questions.findIndex((question) => {
    return question.id == "desired_makeup_product" || question.id == "desired_skincare_product" || question.id == "desired_hair_product"
  }) ?? 13) + 1

  if (currentQuestion.value < firstProductTypeStep) {
    navigateTo({ name: route.name!, params: { step: firstProductTypeStep } })
  }
}

const updateStep = () => {
  const stepParam = route.params["step"]

  if (typeof stepParam === "string") {
    const step = parseInt(stepParam, 10)

    if (step >= 1 && step <= (questionnaire.value?.questions?.length ?? 0)) {
      currentQuestion.value = step
    } else {
      navigateTo({ name: route.name!, params: { step: "1" } }, { replace: true })
    }
  }
}

updateStep()

if (error.value) {
  console.error(error.value)

  notifications.error("Não foi possível carregar os dados do seu perfil de beleza")
}
</script>
<style lang="sass" scoped>
.edit-profile
  position: relative
  max-width: 988px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  .actions
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    gap: 16px

    .hide
      opacity: 0
      pointer-events: none

    .round-button
      flex-shrink: 0

.edit-profile-loading
  display: flex
  height: 320px
  justify-content: center
  align-items: center
</style>
